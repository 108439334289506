import axios from "axios";

export class FileController {
  upload = async (filename: string, file: string | Blob) => {
    const formData = new FormData();
    formData.append(filename, file);

    const response = await axios.get(
      "https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/files/getSignedUrl",
      {
        params: {
          filename,
        },
      }
    );

    const signedUrl: string = response?.data?.url;
    await this.uploadUsingSignedUrl(signedUrl, file);

    return signedUrl.split("?")[0];
  };

  private uploadUsingSignedUrl = async (uploadURL: string, file: any) => {
    const binary = atob(file.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const blobData = new Blob([new Uint8Array(array)]);
    await fetch(uploadURL, {
      method: "PUT",
      body: blobData,
    });
  };
}
