<template>
  <div>
    <Header></Header>
    <div class="new-report container bg-light p-4 mt-4 rounded-3">
      <!-- Error Modal -->
      <div
        class="modal fade"
        id="errorModal"
        tabindex="-1"
        aria-labelledby="errorModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body" v-if="message">
              <div v-bind:class="`alert alert-${message.type}`">
                <strong v-text="message.prefix"></strong>
                <span v-text="message.message"></span>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="message">
        <div class="col-md-12">
          <div v-bind:class="`alert alert-${message.type}`">
            <strong v-text="message.prefix"></strong>
            <span v-text="message.message"></span>
          </div>
        </div>
      </div>

      <div class="row" v-if="loading">
        <div class="col-md-12">
          <span>Caricamento...</span>
        </div>
      </div>

      <div class="row mb-3" v-if="!message?.block && !loading">
        <div class="col-md-12 text-center">
          <h1>Nuovo Intervento / New Intervention</h1>
        </div>
      </div>
      <div class="row" v-if="!message?.block && !loading">
        <div class="col-md-12">
          <form action="" class="form">
            <div class="row">
              <div class="col-md-6">
                <label for="report-email" class="form-label"
                  >Data dell'intervento / Date of intervention *</label
                >
                <input
                  type="date"
                  class="form-control"
                  id="report-date"
                  v-model="report.data_intervento"
                  required
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <label for="report-notes" class="form-label">Note</label>
                <textarea
                  class="form-control"
                  id="report-notes"
                  rows="3"
                  v-model="report.note_intervento"
                ></textarea>
              </div>
            </div>

            <div class="report-item mt-3" v-for="index in maxItems" :key="index">
              <div class="row">
                <div class="col-md-12">
                  <h4>Articolo {{ index }} / Article {{ index }}</h4>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label
                    v-bind:for="`report-item-${index}-name`"
                    class="form-label"
                    >Nome / Name <span v-if="index === 1">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :id="`report-item-${index}-name`"
                    v-model="report[`articolo_${index}`]"
                  />
                </div>
                <div class="col-md-6">
                  <label
                    v-bind:for="`report-item-${index}-quantity`"
                    class="form-label"
                    >Quantità / Quantity <span v-if="index === 1">*</span></label
                  >
                  <input
                    type="number"
                    class="form-control"
                    min="0"
                    id="report-item-${id}-quantity"
                    v-model="report[`qta_articolo_${index}`]"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <h4>Diritto di chiamata</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="report-call"
                    v-model="report.diritto_chiamata_riscosso"
                  />
                  <label class="form-check-label" for="report-call">
                    Diritto di chiamata riscosso / Contributions Charges
                  </label>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4">
                <label for="report-work-time" class="form-label"
                  >Ore di Intervento / Intervention Hours *</label
                >
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="report-work-time"
                  v-model="report.ore_di_lavoro"
                  required
                />
              </div>

              <div class="col-md-4">
                <label for="report-trip-time" class="form-label"
                  >Ore di Viaggio / Travel Hours *</label
                >
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="report-trip-time"
                  v-model="report.ore_viaggio"
                  required
                />
              </div>

              <div class="col-md-4">
                <label for="report-trip-distance" class="form-label"
                  >Chilometri percorsi / Kilometers *</label
                >
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="report-trip-distance"
                  v-model="report.chilometri"
                  required
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <label for="report-file-report" class="form-label"
                  >File</label
                >
                <a :href="report.rapportino" v-if="report.rapportino" class="mx-2"
                  >Scarica file</a
                >
                <input
                  type="file"
                  class="form-control"
                  id="report-file-report"
                  aria-label="Upload"
                  name="rapportino"
                  aria-filename="'rapportino.jpg'"
                  accept="image/*,video/mp4,video/x-m4v,video/*"
                  @change="fileUpload"
                  required
                />
              </div>

              <!-- <div class="col-md-6">
                <label for="report-file-picture" class="form-label">Foto / Photo</label>
                <a
                  :href="report.foto_del_prodotto"
                  v-if="report.foto_del_prodotto"
                  class="mx-2"
                  >Scarica foto</a
                >
                <input
                  type="file"
                  class="form-control"
                  id="report-file-picture"
                  aria-label="Upload"
                  name="foto_del_prodotto"
                  accept="image/*,video/mp4,video/x-m4v,video/*"
                  @change="fileUpload"
                />
              </div> -->
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="submit"
                  :disabled="loadingFiles"
                >
                  Carica Intervento / Register Intervention
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { patch } from "axios";
import { HubspotController } from "../controller/hubspot";
import { FileController } from "../controller/files";

import { Modal } from "bootstrap";
import Footer from "../components/Footer.vue"
import Header from "../components/Header.vue"

export default {
  data() {
    return {
      loading: true,
      loadingFiles: false,
      message: null,
      hsController: new HubspotController(),
      report: {
        rapportino: "",
      },
      items: [],
      maxItems: 4,
      required: [
        "data_intervento",
        "articolo_1",
        "qta_articolo_1",
        "ore_di_lavoro",
        "ore_viaggio",
        "chilometri"
      ],
    };
  },

  mounted() {
    document.title = 'Fantini | Nuovo Intervento / New Intervention'

    this.loading = true;
    const ticketId = this.$route.query.ticketId;
    if (!ticketId) {
      this.message = {
        type: "danger",
        prefix: "Attenzione:",
        message:
          "URL errato, si prega di digitare di nuovo l'indirizzio in modo corretto",
      };

      this.loading = false;
      return false;
    }

    this.hsController
      .getTicket(ticketId)
      .then((ticket) => {
        this.report = ticket.properties;
        this.report.id = ticket.id;
      })
      .catch((e) => {
        console.log(e);
        this.message = {
          type: "danger",
          prefix: "Attenzione:",
          message:
            "Ticket non trovato, potrebbe essere stato cancellato oppure l'ID non essere corretto",
          block: true,
        };
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    removeItem(id) {
      console.log(`Remove item ${id}`);
      for (let i = 1; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          this.items.splice(i, 1);
        }
      }
    },

    addItem() {
      this.items.push({
        id: this.items[this.items.length - 1].id + 1,
      });
    },

    async submit() {
      this.loading = true;

      console.log(JSON.stringify(this.report));

      for (let field of this.required) {
        if (!this.report[field]) {
          this.message = {
            type: "warning",
            prefix: "Attenzione:",
            message: " Compila tutti i campi richiesti.",
          };
          console.error(`Campo non compilato: ${field}`);
          const modal = new Modal(document.getElementById("errorModal"));
          modal.show();

          this.loading = false;
          return false;
        }
      }

      const payload = this.hsController.sanitizePayload(this.report)

      patch(
        "https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/tickets/" +
          this.report.id,
        payload
      )
        .then((response) => {
          this.message = {
            type: "success",
            prefix: "Successo:",
            message: "Ticket salvato con successo.",
          };
        })
        .catch((e) => {
          this.message = {
            type: "danger",
            prefix: "Attenzione:",
            message:
              "Errore durante l'invio della richiesta, contattare l'assitenza.",
          };
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async fileUpload(event) {
      this.loadingFiles = true;
      const fileController = new FileController();

      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      const file = files[0];
      const name = event.target.name;
      let filename;

      const MAX_IMAGE_SIZE = 10000000;
      let reader = new FileReader();
      reader.onload = async (e) => {
        if (e.target.result.length > MAX_IMAGE_SIZE) {
          this.message = {
            type: "danger",
            prefix: "Attenzione:",
            message: "File is loo large - 10Mb maximum.",
          };
          const modal = new Modal(document.getElementById("errorModal"));
          modal.show();

          return false;
        }

        filename = `${name}.${file?.name?.split(".")[file?.name?.split(".").length-1]}`;
        console.log(filename)
        const finalFile = e.target.result;

        try {
          this.report[name] = await fileController.upload(filename, finalFile);
        } catch (e) {
          this.message = {
            type: "danger",
            prefix: "Attenzione:",
            message: "Errore con il file appena selezionato",
          };
        } finally {
          this.loadingFiles = false;
        }
      };
      reader.readAsDataURL(file);
    },
  },

  components:{
    Header,
    Footer
  }
};
</script>
